import React from 'react';
// import Img from 'gatsby-image';
import {StaticQuery, graphql} from 'gatsby';
import PortfolioItem from './PortfolioItem'
// import FullScreenPanel from './FullScreenPanel'
import portfolioCSS from './portfolio.module.sass';

const Portfolio = ({children, data }) => (

  <StaticQuery
    query={
      graphql`
        query PortfolioItemsQuery {
          allPortfolioItemsJson {
            edges {
              node {
                name
                desc
                url
                image
                role
              }
            }
          }
        }
    `}
    render={data => (
      <>
        <section className={portfolioCSS.portfolio}>
          <ul className={portfolioCSS.list}>{getPortfolioItems(data)}</ul>
        </section>
      </>
    )}

    // { <Img fixed={data.file.childImageSharp.fixed} />; }
  />
);

function getPortfolioItems(data) {
  const portfolioItemsArray = [];
  data.allPortfolioItemsJson.edges.forEach(item =>
    //portfolioItemsArray.push(<li key={item.node.name}>{item.node.image}{item.node.name}</li>)

//    <PortfolioImage imgsrc={portfolioItem.node.image} />  // need to get image and verify existence before creating a PortfolioItem

    portfolioItemsArray.push(<PortfolioItem key={item.node.name} portfolioItem={item} />)
  )
  return portfolioItemsArray;

}

export default Portfolio;

// export const query = graphql`
//   query PortfolioItemsQuery {
//     allPortfolioItemsJson {
//       edges {
//         node {
//           name
//           url
//           image
//         }
//       }
//     }
//   }
// `;

// export const query = graphql`
//   PortfolioItemsQuery {
//     file(relativePath: { eq: "blog/avatars/kyle-mathews.jpeg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

// export const query = graphql`
//   PortfolioItemsQuery {
//     file(relativePath: { eq: "blog/avatars/kyle-mathews.jpeg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `