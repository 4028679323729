import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default class PortfolioImage extends React.Component {
  render () {
    const { imgsrc, addClass } = this.props
    // console.log (this.props);

    // console.log(this.props.imgsrc);
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // console.log(data);

          const foundImage = 
                data.allImageSharp.edges.find(element => {
                  // Match string after final slash
                  // console.log(element);
                  // console.log("source image: " + this.props.imgsrc);
                  // console.log(element.node.fluid.src.split('/').pop() === imgsrc);

                  // if(element.node.fluid.src.split('/').pop() === imgsrc) return element;

                  return (
                    element.node.fluid.src.split('/').pop() === imgsrc
                  );
                })
                
                //.node.fluid

          //console.log('found: '+foundImage);

          if(foundImage) {
            //console.log(foundImage);
            //console.log('addClass: ' + addClass);
            return (
              <Img
                className={addClass}
                fluid={foundImage.node.fluid}
              />
            );
          }

          return false;

        }}
      />
    );
  }
}
