import React from 'react';
// import Img from 'gatsby-image';
// import {StaticQuery, graphql} from 'gatsby';
import PortfolioImage from './PortfolioImage'

import portfolioitemCSS from './portfolioitem.module.sass';

const PortfolioItem = ({children, portfolioItem }) => (

      // <>
        <li className={portfolioitemCSS.item}>
          <PortfolioImage imgsrc={portfolioItem.node.image} className={portfolioitemCSS.image} addClass={"test"} />
          <p className={portfolioitemCSS.desc}><a href={portfolioItem.node.url}>{portfolioItem.node.name}</a>, {portfolioItem.node.desc}</p>
          <strong className={portfolioitemCSS.role}>{portfolioItem.node.role}</strong>
          {/* { <Img fixed={portfolioItem.file.childImageSharp.fixed} /> } */}
        </li>
      // </>
);

export default PortfolioItem;