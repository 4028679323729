import React from 'react';

import fullScreenPanelCSS from './fullscreenpanel.module.sass';

const FullScreenPanel = ({children}) => (
  <div className={fullScreenPanelCSS.panel}>
    {children}
  </div>
);

export default FullScreenPanel;
