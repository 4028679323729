import React from 'react';
import contactinfoCSS from './contactinfo.module.sass';

const ContactInfo = () => (

  // <>
  <address className={contactinfoCSS.contactinfo}>

    <div className={contactinfoCSS.location}>
      <small className={contactinfoCSS.city}>Kamloops</small>
      <a className={contactinfoCSS.tel} href="tel:+1 2503182905">1.250.318.2905</a>
    </div>

    <div className={contactinfoCSS.location}>
      <small className={contactinfoCSS.city}>Berlin</small>
      <a className={contactinfoCSS.tel} href="tel:+49 1711925486">+49.171.192.5486</a>
    </div>

  </address>
  // </>
);

export default ContactInfo;