/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from "react"
// import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby"

// import Header from "./header"
import textPanelCSS from "./textpanel.module.sass"

const TextPanel = ({ children }) => (
  <div className={textPanelCSS.panel}>
    {children}
  </div>
)

// Footer.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default TextPanel
