import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FullScreenPanel from '../components/FullScreenPanel'
import TextPanel from '../components/TextPanel'
import Portfolio from '../components/Portfolio';
import ContactInfo from '../components/ContactInfo';

import DownArrow from '../images/svg/arrow.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <FullScreenPanel>
      <TextPanel>
        <p>We make websites. Starting with the ambitious <a href="//field.ca">Yoho National Park Travel Guide</a> in 2005, our passion has been creating useful, user-centric digital things. Have a look at our work, and let's make something fresh.</p>
        {/* <span className="scrolldown">&darr;</span> */}
        <DownArrow className="scrolldown" />
      </TextPanel>
    </FullScreenPanel>
    <Portfolio />
    <FullScreenPanel>
      <ContactInfo />
    </FullScreenPanel>

  </Layout>
)

export default IndexPage
